import groupBy from "lodash/groupBy"

export const state = () => ({
    data: {},
    premiumData: {},
    cleanData: [],
    newData: {},
    newPremiumData: {},
    loading: false,
    error: {}
})

export const mutations = {
    set_newData(state, data) {
        state.newData = groupBy(data, "marketId")
    },
    reset_newData(state) {
        state.newData = {}
    },
    set_premiumData(state, data) {
        state.clearPremiumData = data
        state.premiumData = groupBy(data, "marketUniqueId")
    },
    set_newPremiumData(state, data) {
        state.newPremiumData = groupBy(data, "marketUniqueId")
    },
    reset_newPremiumData(state) {
        state.newPremiumData = {}
    },
    reset_premiumData(state) {
        state.premiumData = {}
    },
    set_data(state, data) {
        // console.log(data)
        state.cleanData = data
        state.data = groupBy(data, "marketId")
        // console.log(state.data)
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}

export const actions = {
    async get({ commit }, matchIds, refresh) {
        if (refresh) {
            commit("set_data", {})
        }
        let accessToken
        if (this.$auth.loggedIn) {
            accessToken = this.$auth.getToken("customLocal")
        }
        try {
            // commit('set_loading', true)
            const { data } = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "book/getBooksForMarkets",
                data: {
                    _accessToken: accessToken,
                    matchIds,
                    isFancy: true
                }
            })
            commit("set_data", data.data)
            // commit('set_loading', false)
            this.commit("matchInside/filter_fancy", data.data)
        } catch (error) {
            commit("set_error", error)
            // commit('set_loading', false)
        }
    },
    async getAll({ commit }, matchIds, refresh) {
        if (refresh) {
            commit("set_data", {})
        }
        let accessToken
        if (this.$auth.loggedIn) {
            accessToken = this.$auth.getToken("customLocal")
        }
        try {
            // commit('set_loading', true)
            const { data } = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "book/getBooksForAllMarkets",
                data: {
                    _accessToken: accessToken,
                    matchIds
                }
            })
            commit("set_data", data.data)
            // commit('set_loading', false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getAllPremium({ commit }, { refresh, dataSet }) {
        let outputURL = "UserNetExposure/getBooksForAllMarkets"
        if (dataSet) {
            outputURL =
                "UserNetExposure/getBooksForAllMarkets?eventId=" + dataSet
        }
        if (refresh) {
            commit("reset_premiumData")
        }
        try {
            if (dataSet) {
                // console.log('payload', dataSet)
                const { data } = await this.$axios({
                    method: "get",
                    baseURL: process.env.UOF_SPORTSRADAR_SERVICE_URL,
                    // url: 'UserNetExposure/getBooksForAllMarkets?eventId=' + outputURL
                    url: outputURL
                })
                if (data && data.data && data.data.outputArray) {
                    commit("set_premiumData", data.data.outputArray)
                    this.commit(
                        "matchInside/filter_premium",
                        data.data.outputArray
                    )
                }
            }
            // commit('set_loading', false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
