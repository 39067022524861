import socket from "@/plugins/socket.client"
export const state = () => ({
    socketId: undefined
})

export const mutations = {
    RESET_SOCKET_ID(state) {
        state.socketId = undefined
    },
    SET_SOCKET_ID(state, data) {
        state.socketId = data
    }
}

export const actions = {
    joinRooms({ commit, state }, { socketId, marketId }) {
        // console.log(socketId, marketId)
        commit("SET_SOCKET_ID", socketId)
        if (marketId) {
            try {
                socket.commonSocket.emit("joinRooms", {
                    roomNames: ["odds_change_" + marketId]
                })
            } catch (error) {
                const errorData = {}
                errorData.roomNames = state.ids
                this.commit("socketMain/socketFailureLog", error, errorData)
            }
        }
    },
    leaveRooms({ state, commit }, { marketId }) {
        // console.log(marketId)
        try {
            if (marketId) {
                socket.commonSocket.emit("leaveRooms", {
                    roomNames: ["odds_change_" + marketId]
                })
            }
        } catch (error) {
            const errorData = {}
            errorData.roomNames = marketId
            this.commit("socketMain/socketFailureLog", error, errorData)
        }
    }
}
