export const state = () => ({
    data: [],
    fancy: [],
    premium: [],
    lineSession: [],
    matchIds: [],
    openedPanels: [],
    openedPanelsThreeEt: [],
    loading: false,
    configlimits: undefined,
    configlimitsType: undefined,
    getVideoUrlRtmp: undefined,
    error: "",
    minMaxMarket: undefined,
    minMaxMarketPremium: undefined,
    minMaxMarketLoading: false,
    resMarketIds: undefined,
    resBookmaker: undefined
})

export const mutations = {
    filter_fancy(state, data) {
        let bookIds = []
        if (data && data.length > 0) {
            bookIds = data.map((item) => item.marketId)
        }
        const fancy = state.fancy
        fancy.map((item) => {
            if (bookIds.includes(item.betfairId)) {
                item.fancySort = 0
            } else {
                item.fancySort = 1
            }
            return item
        })

        state.fancy = fancy.sort((a, b) => {
            if (a.fancySort > b.fancySort) return 1
            if (a.fancySort < b.fancySort) return -1
            return 0
        })
    },
    set_matchIds(state, data) {
        state.matchIds = data
        this.commit("placeBet/set_matchIds", state.matchIds)
    },
    set_data_configlimits(state, data) {
        state.configlimits = data.data
    },
    set_data_liveTv(state, data) {
        state.getVideoUrlRtmp = data
    },
    add_to_matchIds(state, data) {
        state.matchIds = [...state.matchIds, ...data]
        this.commit("placeBet/set_matchIds", state.matchIds)
        this.commit("socketRate/SET_IDS", [state.matchIds])
    },
    reset(state) {
        state.data = []
        state.fancy = []
        state.lineSession = []
        state.premium = []
    },
    set_data(state, data) {
        state.openedPanels = [...data, 100].map((m, i) => i)
        state.data = data
    },
    set_line_session(state, data) {
        state.lineSession = data
    },
    set_fancy(state, data) {
        state.fancy = data.filter(
            (item) =>
                item.status === "Enable" || item.status === "resultDeclared"
        )
        this.commit("matchInside/filter_fancy", this.state.betBooks.cleanData)
    },
    set_single_fancy(state, data) {
        const fancyData = state.fancy
        const index = fancyData.findIndex((el) => el._id === data._id)
        if (index >= 0) {
            fancyData[index] = data
            state.fancy = fancyData
        }
    },
    filter_premium(state, data) {
        let bookIds = []
        if (data && data.length > 0) {
            bookIds = data.map((item) => item.marketUniqueId)
        }
        const premium = state.premium
        premium.map((item) => {
            if (bookIds.includes(item._id)) {
                item.premiumSort = 0
            } else {
                item.premiumSort = 1
            }
            return item
        })

        state.premium = premium.sort((a, b) => {
            if (a.premiumSort > b.premiumSort) return 1
            if (a.premiumSort < b.premiumSort) return -1
            return 0
        })
    },
    set_premium(state, data) {
        state.premium = data
        this.commit(
            "matchInside/filter_premium",
            this.state.betBooks.clearPremiumData
        )
    },
    set_error(state, data) {
        state.error = data
        this.commit("snackbar/open", {
            text: state.error,
            color: "error"
        })
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_min_max_market(state, data) {
        state.minMaxMarket = data
    },
    set_min_max_market_premium(state, data) {
        state.minMaxMarketPremium = data
    },
    set_min_max_market_loading(state, data) {
        state.minMaxMarketLoading = data
    },
    set_resMarketIds(state, data) {
        state.resMarketIds = data
    },
    set_resBookmaker(state, data) {
        state.resBookmaker = data
    }
}

export const actions = {
    getSocketMarketData(
        { commit },
        { marketId, linesession, status, marketData }
    ) {
        if (linesession && status === "winnerdeclared") {
            const socketMarketData = marketData.data.filter(function (obj) {
                return obj.betfairId !== marketId
            })
            const socketMarketDataArry = {
                data: socketMarketData
            }
            commit("set_line_session", socketMarketDataArry)
        }
    },
    async getFancy({ commit }, { market }) {
        try {
            let _accessToken
            if (this.$auth.loggedIn) {
                _accessToken = this.$auth.getToken("customLocal")
            }
            const { data } = await this.$axios({
                method: "post",
                baseURL: process.env.CUSTOMER_SERVICE_URL,
                url: "SubMarket/getAll",
                data: { market, _accessToken }
            })
            if (Array.isArray(data)) {
                const matchIds = data.map((match) => match.betfairId)

                if (this.$router.currentRoute.params) {
                    this.commit(
                        "socketCustomerService/SET_IDS_SINGLE",
                        matchIds
                    )
                } else {
                    this.commit("socketCustomerService/SET_IDS_SINGLE", [])
                }

                commit("add_to_matchIds", matchIds)
                commit("set_fancy", data)
            } else {
                this.commit("socketCustomerService/SET_IDS_SINGLE", [])
                commit("set_fancy", [])
            }
        } catch (error) {}
    },
    async getAllUOFMarketsByEventId({ commit }, { payload, socketData }) {
        try {
            let data = {}
            if (socketData) {
                commit("set_premium", socketData)
            } else if (!socketData && payload) {
                data = await this.$axios({
                    method: "get",
                    baseURL: process.env.UOF_SPORTSRADAR_SERVICE_URL,
                    url: "/Market/getAllUOFMarketsByEventId/" + payload
                })
            } else {
                data = []
            }

            if (data?.data?.success === true) {
                commit("set_premium", data.data.data.outputArray)
            }
        } catch (error) {}
    },
    async getMarketsById(
        { commit, state },
        {
            game,
            parentId,
            loading = true,
            socketData,
            bookmaker = true,
            lineSessionStatus = true,
            martketOnOff
        }
    ) {
        let userId
        let _accessToken
        // let difference = arrA.filter(x => !arrB.includes(x));
        if (this.$auth.loggedIn) {
            userId = this.$auth.user._id
            _accessToken = this.$auth.getToken("customLocal")
        }

        const getMarketIds = async () => {
            try {
                const response = await this.$axios({
                    method: "post",
                    baseURL: process.env.SPORTSBOOK_API_URL,
                    url: "Category/getMarketIds",
                    data: {
                        game,
                        _id: userId,
                        _accessToken,
                        parentId,
                        accessLevel: "Player"
                    }
                })
                return response
            } catch (error) {
                console.log(error)
                const response = {
                    data: { value: false }
                }
                return response
            }
        }
        let getCustomMarketAndRunner
        if (game !== "Cricket" || bookmaker === true) {
            getCustomMarketAndRunner = async () => {
                try {
                    const response = await this.$axios({
                        method: "post",
                        baseURL: process.env.SPORTSBOOK_API_URL,
                        url: "Category/getCustomMarketAndRunner",
                        data: {
                            parentId,
                            _accessToken
                        }
                    })
                    return response
                } catch (error) {
                    console.log(error)
                    const response = {
                        data: { value: false }
                    }
                    return response
                }
            }
        } else {
            getCustomMarketAndRunner = () => {
                const response = {
                    data: { value: false }
                }
                return response
            }
        }
        let lineSession
        // eslint-disable-next-line prefer-const
        lineSession = () => {
            const response = {
                data: { value: false }
            }
            return response
        }
        // console.log(lineSessionStatus)
        if (lineSessionStatus && process.env.LINE_SESSSION_URL) {
            lineSession = async () => {
                // lineSession = () => {
                try {
                    // const response = {
                    //   data: { value: false }
                    // }
                    // return response
                    const response = await this.$axios({
                        method: "post",
                        baseURL: process.env.LINE_SESSSION_URL,
                        url: "linesession/getlinesession",
                        data: {
                            _accessToken,
                            eventId: parentId
                        }
                    })
                    return response.data
                } catch (error) {
                    const response = {
                        data: { value: false }
                    }
                    return response
                }
            }
        } else {
            lineSession = () => {
                const response = {
                    data: { value: false }
                }
                return response
            }
        }
        try {
            commit("set_loading", loading)
            if (
                socketData &&
                socketData !== "No data found" &&
                socketData.length > 0
            ) {
                const lineSessionData = socketData.filter(
                    (v) =>
                        v.level.includes("betfair-line-sessions") &&
                        !v.betfairId.includes("#bookmaker")
                )
                const marketIdsData = socketData.filter(
                    (v) =>
                        !v.betfairId.includes("#bookmaker") &&
                        !v.level.includes("betfair-line-sessions")
                )
                if (
                    marketIdsData &&
                    marketIdsData.length > 0 &&
                    (lineSessionData.length === 0 || !lineSessionData)
                ) {
                    const data = {
                        data: {
                            value: true,
                            data: marketIdsData
                        }
                    }
                    commit("set_resMarketIds", data)
                }
                const bookmakerData = socketData.filter((v) =>
                    v.betfairId.includes("#bookmaker")
                )
                if (bookmakerData && bookmakerData.length > 0) {
                    let data = {
                        data: {
                            value: true,
                            data: bookmakerData
                        }
                    }
                    if (game === "Cricket" && bookmaker === false) {
                        data = {
                            data: {
                                value: false,
                                data: ""
                            }
                        }
                    }
                    commit("set_resBookmaker", data)
                }
                if (
                    lineSessionStatus &&
                    lineSessionData &&
                    lineSessionData.length > 0
                ) {
                    const data = {
                        data: {
                            value: true,
                            data: lineSessionData
                        }
                    }
                    // console.log('lineSessionData inside', data.data)
                    commit("set_line_session", data.data)
                }
            } else {
                const [resMarketIds, resBookmaker, reslineSession] =
                    await Promise.all([
                        getMarketIds(),
                        getCustomMarketAndRunner(),
                        lineSession()
                    ])
                commit("set_resMarketIds", resMarketIds)
                commit("set_resBookmaker", resBookmaker)
                commit("set_line_session", reslineSession)
            }
            let response1
            response1 = state.resMarketIds
            const response2 = state.resBookmaker
            const response3 = state.lineSession
            if (
                response1 &&
                response1.data &&
                response1.data.value &&
                response1.data.data.length > 0 &&
                martketOnOff &&
                martketOnOff.length
            ) {
                const difference = response1.data.data.filter(
                    (page1) =>
                        !martketOnOff.find((page2) => page1.name === page2.name)
                )
                response1 = {
                    data: {
                        data: difference,
                        value: true
                    }
                }
            }
            // const response2 = state.resBookmaker

            let data1 = []
            let matchIds1 = []
            if (
                response1.data.value &&
                Array.isArray(response1.data.data) &&
                response1.data.data.length > 0
            ) {
                data1 = response1.data.data.map((v) => {
                    if (v.betfairId.includes("#bookmaker")) {
                        v.bookMakerCustom = true
                    }
                    return v
                })
                matchIds1 = response1.data.data.map((match) => match.betfairId)
                for (let i = 0; i < data1.length; i++) {
                    if (data1.length > 0) {
                        if (data1[i].eventType.name === "Soccer") {
                            data1.sort((a, b) =>
                                a.displayOrder > b.displayOrder ? 1 : -1
                            )
                        }
                    }
                }
            } else if (!response1.data.value) {
                commit("set_error", response1.data.error)
            }

            let data2 = []
            let matchIds2 = []
            if (
                response2 &&
                response2.data &&
                response2.data.value &&
                Array.isArray(response2.data.data) &&
                response2.data.data.length > 0
            ) {
                data2 = response2.data.data
                matchIds2 = response2.data.data.map((match) => match.betfairId)
            }
            let matchIds3 = []

            if (
                response3 &&
                response3.data &&
                Array.isArray(response3.data) &&
                response3.data.length > 0
            ) {
                matchIds3 = response3.data.map((match) => match.betfairId)
            }

            const marketData = [...data1, ...data2]

            // setting match ids for socket on
            if (matchIds1.length > 0 || matchIds3.length > 0) {
                this.commit("socketRate/SET_IDS", [...matchIds1, ...matchIds3])
            }

            this.commit("socketBookmaker/SET_IDS", [...matchIds1, ...matchIds2])
            this.commit("socketRate/SET_IDS", [
                ...matchIds1,
                ...matchIds2,
                ...matchIds3
            ])

            commit("set_matchIds", [...matchIds1, ...matchIds2, ...matchIds3])
            commit("set_data", marketData)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getConfigMaxLimits({ commit }, { game, loading = true }) {
        let _accessToken
        if (this.$auth.loggedIn) {
            _accessToken = this.$auth.getToken("customLocal")
        }
        try {
            const response1 = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "EventTypeMaxLimit/getConfigMaxLimits",
                data: {
                    game,
                    _accessToken
                }
            })
            response1.data.data.typeConfigLimits = "api"
            commit("set_data_configlimits", response1.data)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error.response)
        }
    },
    async getMinMaxMarket(
        { commit },
        {
            game,
            loading = true,
            marketId,
            oddsSelected,
            selectionId,
            position,
            type,
            bettingType,
            threeETId,
            allmaxbets = false
        }
    ) {
        let _accessToken
        if (this.$auth.loggedIn) {
            _accessToken = this.$auth.getToken("customLocal")
        }
      let baseurl = process.env.SPORTSBOOK_API_URL
        if (bettingType === "lineLadder") {
            baseurl = process.env.LINE_SESSSION_URL
        }
        try {
            commit("set_min_max_market_loading", loading)
            const response = await this.$axios({
                method: "post",
                baseURL: baseurl,
                url: "/Category/getMinMaxMarket",
                data: {
                    game,
                    marketId,
                    oddsSelected,
                    selectionId,
                    position,
                    type,
                    allmaxbets,
                    threeETId,
                    _accessToken
                }
            })
            if (response && response.data && response.data.value) {
                commit("set_min_max_market", response.data.data)
                commit("set_min_max_market_loading", false)
            } else {
                commit("set_min_max_market", response.data.data)
                throw response.data
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            if (error && error.data && !allmaxbets) {
                commit("set_min_max_market", error.data)
                commit("set_error", error.data)
            } else if (error && error.message && !allmaxbets) {
                commit("set_min_max_market", error.message)
                commit("set_error", error.message)
            } else {
                // eslint-disable-next-line no-console
                commit("set_min_max_market", "Network Error")
                console.log("error.....................", error)
            }
            commit("set_min_max_market_loading", false)
        }
    },
    async getMinMaxMarketPremium({ commit }, { marketId, loading }) {
        try {
            commit("set_min_max_market_loading", loading)
            const response = await this.$axios({
                method: "get",
                baseURL: process.env.UOF_SPORTSRADAR_SERVICE_URL,
                url: "/Market/getMinMaxMarketForPlayer/Cricket/" + marketId
            })
            // console.log(response.data.data.outputObject)
            if (
                response &&
                response.data &&
                response.data &&
                response.data.data
            ) {
                commit(
                    "set_min_max_market_premium",
                    response.data.data.outputObject
                )
                commit("set_min_max_market_loading", false)
            } else {
                commit("set_min_max_market_premium", response.data.data)
                throw response.data
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            commit("set_min_max_market_loading", false)
        }
    },
    async getVideoUrlRtmp({ commit }, { channel, loading = false }) {
        try {
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "Category/getVideoStreamUrl",
                data: {
                    // clientIp,
                    channel
                }
            })
            commit("set_data_liveTv", response.data.data)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }
}
