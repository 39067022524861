export default ({ store, route }) => {
    if (process.env.setUp === "fps" || route.path === "/user-book") return
    if (window) {
        // chat
        // setTimeout(() => {
        if (process.env.APP_TITLE && process.env.APP_TITLE === "SuperWin") {
            setTimeout(() => {
                ;(function (d, w, c) {
                    if (!d.getElementById("spd-busns-spt")) {
                        const n = d.getElementsByTagName("script")[0]
                        const s = d.createElement("script")
                        let loaded = false
                        s.id = "spd-busns-spt"
                        s.async = "async"
                        s.setAttribute("data-self-init", "false")
                        s.setAttribute("data-init-type", "opt")
                        s.src =
                            "https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js"
                        s.setAttribute(
                            "data-client",
                            "1222081f992e3fb70a8ff643142db5a2566bab6d"
                        )
                        s.setAttribute(
                            "data-bot-hash",
                            "787ddfcaf827e893c28def0b29aea14020143e1a"
                        )
                        s.setAttribute("data-env", "prod")
                        s.setAttribute("data-region", "in")
                        if (c) {
                            s.onreadystatechange = s.onload = function () {
                                if (!loaded) {
                                    c()
                                }
                                loaded = true
                            }
                        }
                        n.parentNode.insertBefore(s, n)
                    }
                })(document, window, function () {
                    window.Freshbots.initiateWidget()
                })
            }, 7000)
        } else {
            console.log("in script")
            const FC = document.createElement("script")
            FC.src = "https://wchat.freshchat.com/js/widget.js"
            document.head.appendChild(FC)

            function initFreshChat() {
                console.log("in initFreshChat")
                window.fcWidget.init({
                    token: "0970f79a-205a-421e-9e03-96503a5e021e",
                    host: "https://one5commute.freshchat.com"
                })
            }
            function initialize(i, t) {
                console.log("in initialize")
                let e = i.getElementById(t)
                if (e) {
                    console.log("in if")
                    // If the element with ID 't' already exists, you can directly call initFreshChat()
                    initFreshChat()
                } else {
                    console.log("in if")
                    // If the element doesn't exist, create the script element and load the Freshchat widget
                    e = i.createElement("script")
                    e.id = t
                    e.async = true
                    console.log("appendChild e1", e)
                    e.src = "https://one5commute.freshchat.com/js/widget.js"
                    console.log("appendChild e2", e)
                    e.onload = initFreshChat()
                    console.log("appendChild e3", e)
                    i.head.appendChild(e)
                }
            }
            function initiateCall() {
                console.log("in initiateCall")
                console.log("in document", document)
                initialize(document, "Freshchat-js-sdk")
            }
            setTimeout(() => {
                initiateCall()
                // window.addEventListener
                //     ? window.addEventListener("load", initiateCall, !1)
                //     : window.attachEvent("load", initiateCall, !1)
            }, 3000)

            // ;(function (d, w, c) {
            //     if (!d.getElementById("spd-busns-spt")) {
            //         const n = d.getElementsByTagName("script")[0]
            //         const s = d.createElement("script")
            //         let loaded = false
            //         s.id = "spd-busns-spt"
            //         s.async = "async"
            //         s.setAttribute("data-self-init", "false")
            //         s.setAttribute("data-init-type", "opt")
            //         s.src =
            //             "https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js"
            //         s.setAttribute(
            //             "data-client",
            //             "1222081f992e3fb70a8ff643142db5a2566bab6d"
            //         )
            //         s.setAttribute(
            //             "data-bot-hash",
            //             "aa6ee4cdf88ba3b9cc7f4a63e40e0673df227ed0"
            //         )
            //         s.setAttribute("data-env", "prod")
            //         s.setAttribute("data-region", "in")
            //         if (c) {
            //             s.onreadystatechange = s.onload = function () {
            //                 if (!loaded) {
            //                     c()
            //                 }
            //                 loaded = true
            //             }
            //         }
            //         n.parentNode.insertBefore(s, n)
            //     }
            // })(document, window, function () {
            //     window.Freshbots.initiateWidget()
            // })
        }
        // }, 7000)
    }
}
