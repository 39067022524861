/*eslint-disable*/
export default {
    noindexScript() {
        if (process.client) {
            const noIndex = document.createElement("meta")
            noIndex.setAttribute("name", "robots")
            noIndex.setAttribute("content", "noindex")
            document.head.appendChild(noIndex)
        }
    },
    facebookOverallPixel() {
        if (
            process.client &&
            process.env &&
            process.env.APP_TITLE &&
            process.env.APP_TITLE === "SuperWin" &&
            process.env.fairplayEnvironment === "PROD"
        ) {
            /* Inhouse for superwin start */
            const faceBookOne = document.createElement("script")
            faceBookOne.innerHTML = `!(function (f, b, e, v, n, t, s) {
            if (f.fbq) return
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments)
            }
            if (!f._fbq) f._fbq = n
            n.push = n
            n.loaded = !0
            n.version = "2.0"
            n.queue = []
            t = b.createElement(e)
            t.async = !0
            t.src = v
            s = b.getElementsByTagName(e)[0]
            s.parentNode.insertBefore(t, s)
        })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
        )
        fbq("init", "3496555053935146")
        fbq("track", "PageView")`
            document.head.appendChild(faceBookOne)

            const faceBookNoOneScript = document.createElement("noscript")
            faceBookNoOneScript.innerHTML = `<img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=3496555053935146&ev=PageView&noscript=1"/>`
            document.head.appendChild(faceBookNoOneScript)
            /* Inhouse for superwin end */

            /* dgmaze for superwin start */
            const faceBookTwo = document.createElement("script")
            faceBookTwo.innerHTML = `!(function (f, b, e, v, n, t, s) {
            if (f.fbq) return
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments)
            }
            if (!f._fbq) f._fbq = n
            n.push = n
            n.loaded = !0
            n.version = "2.0"
            n.queue = []
            t = b.createElement(e)
            t.async = !0
            t.src = v
            s = b.getElementsByTagName(e)[0]
            s.parentNode.insertBefore(t, s)
        })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
        )
        fbq("init", "3668742613383161")
        fbq("track", "PageView")`
            document.head.appendChild(faceBookTwo)

            const faceBookNoTwoScript = document.createElement("noscript")
            faceBookNoTwoScript.innerHTML = `<img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=3668742613383161&ev=PageView&noscript=1"/>`
            document.head.appendChild(faceBookNoTwoScript)
            /* dgmaze for superwin end */
        }
    },
    dglpFacebookPixel() {
        if (
            process.client &&
            process.env &&
            process.env.APP_TITLE &&
            process.env.APP_TITLE === "SuperWin" &&
            process.env.fairplayEnvironment === "PROD"
        ) {
            /* only for digimaze start */
            const faceBookOne = document.createElement("script")
            faceBookOne.innerHTML = `!(function (f, b, e, v, n, t, s) {
            if (f.fbq) return
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments)
            }
            if (!f._fbq) f._fbq = n
            n.push = n
            n.loaded = !0
            n.version = "2.0"
            n.queue = []
            t = b.createElement(e)
            t.async = !0
            t.src = v
            s = b.getElementsByTagName(e)[0]
            s.parentNode.insertBefore(t, s)
        })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
        )
        fbq("init", "1327858461042381")
        fbq("track", "PageView")`
            document.head.appendChild(faceBookOne)

            const faceBookNoOneScript = document.createElement("noscript")
            faceBookNoOneScript.innerHTML = `<img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=1327858461042381&ev=PageView&noscript=1"/>`
            document.head.appendChild(faceBookNoOneScript)
        }
        /* only for digimaze end */
    },
    // <!-- Script to load componenton scroll Start-->
    onscrollComponentLoad(loadComponent, showSec, mobBreakpoint) {
        var marginSec = loadComponent.getBoundingClientRect().top
        var innerHeight = window.innerHeight
        var sec = mobBreakpoint ? -120 : -50
        if (marginSec - innerHeight < sec) {
            showSec = true
        }
        return showSec
    },
    // <!-- Script to load componenton scroll Start-->

    schemaTag() {
        if (
            process.client &&
            process.env &&
            process.env.APP_TITLE &&
            process.env.APP_TITLE === "SuperWin" &&
            process.env.fairplayEnvironment === "PROD"
        ) {
            const schema = document.createElement("script")
            schema.setAttribute("id", "schema")
            schema.setAttribute("type", "application/ld+json")
            schema.innerHTML = `{
"@context" : "https://schema.org",
"@type" : "Organization",
"Name" : "Superwin",
"URL" : "https://superwin.co/"
}],
"logo" : "https://khelgajeetega.store/website-logo.webp",
"sameAs" : [
"https://www.facebook.com/people/Super-Win/61550203750436/",
"https://www.instagram.com/superwingames/",
"https://www.youtube.com/channel/UCH72lgDDDF_RqJucXmUmYrg",
"https://twitter.com/SuperWinGames",
"https://t.me/superwingames"
]
}`
            document.head.appendChild(schema)
        }
    }
}
