import service from "../services/apiService"
import servicefb from "../services/fbApiService"
import encryptor from "~/plugins/encryption.js"
import constant from "~/constant"
export const state = () => ({
    data: {},
    loading: false,
    error: {},
    userLoginVerify: {},
    whatsappData: {},
    dgWhatsappData: {},
    fantasyConfig: false,
    fantasyPrivateConfig: false,
    configurationData: {},
    notificationData: ""
})

export const mutations = {
    set_notificationData(state, data) {
        if (data) {
            state.notificationData = data
        }
    },
    set_data(state, data) {
        state.data = data
    },
    set_user_verify_data(state, data) {
        state.userLoginVerify = data
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_whatsapp_data(state, data) {
        if (data) {
            state.whatsappData = data
        } else {
            state.whatsappData.linkPage = false
        }
        if (
            state.whatsappData &&
            state.whatsappData.registerBtnLink &&
            localStorage.getItem("signup_affilates")
        ) {
            // If user coming from btag then its always move to registration page
            state.whatsappData.linkPage = true
            state.whatsappData.registerBtnLink = "/registration"
        } else if (
            state.whatsappData &&
            state.whatsappData.registerBtnLink &&
            state.whatsappData.registerBtnLink.startsWith("/")
        ) {
            state.whatsappData.linkPage = true // check for url if link or Page
        } else {
            state.whatsappData.linkPage = false
        }
    },
    clear_whatsapp_data(state) {
        state.whatsappData = {}
        state.whatsappData.linkPage = false
    },
    set_dg_whatsapp_data(state, data) {
        state.dgWhatsappData = data
    },
    set_configData(state, data) {
        state.configurationData = data
    },
    set_fantasy_ON_Off(state, data) {
        if (data && constant.fantasyProShow && process.env.setUp !== "bd-new") {
            state.fantasyConfig = data.isFantasyOnOff
            state.fantasyPrivateConfig = data.isActivePrivateContest
            localStorage.setItem("FANTASYONOFF", data.isFantasyOnOff)
        } else {
            state.fantasyConfig = false
            state.fantasyPrivateConfig = false
            localStorage.setItem("FANTASYONOFF", false)
        }
    }
}

export const actions = {
    async get({ commit }) {
        const _accessToken = this.$auth.getToken("customLocal")
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "member/getOneForUI",
                data: { _accessToken }
            })
            console.log("response", response)
            if (response.data.value) {
                const user = response.data.data
                if (!user.accessToken.includes(_accessToken)) {
                    this.dispatch("user/logout")
                    window.location.reload(true)
                }
                this.$auth.setUser(user)
                commit("set_data", user)
            }

            commit("set_loading", false)
        } catch (error) {
            // this.dispatch('user/logout')
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getOneUiForTripleWallet({ commit }) {
        let _accessToken = ""
        if (localStorage.getItem("accessTokenNewWallet")) {
            const abc = localStorage.getItem("accessTokenNewWallet")
            const jsonToken = JSON.parse(abc)
            if (
                localStorage.getItem("tripleWalletType") &&
                localStorage.getItem("tripleWalletType") === "SportBonus"
            ) {
                _accessToken = jsonToken.accessToken_sp
            } else if (
                localStorage.getItem("tripleWalletType") &&
                localStorage.getItem("tripleWalletType") === "Bonus"
            ) {
                _accessToken = jsonToken.accessToken_bonus
            } else {
                _accessToken = jsonToken.accessToken
            }
        }
        try {
            commit("set_loading", true)
            this.$auth.setToken("customLocal", _accessToken)
            this._setToken("Bearer " + _accessToken)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "member/getOneForUI",
                data: { _accessToken }
            })
            if (response.data.value) {
                const user = response.data.data
                if (!user.accessToken.includes(_accessToken)) {
                    this.dispatch("user/logout")
                    window.location.reload(true)
                }
                this.$auth.setUser(user)
                commit("set_data", user)
            }

            commit("set_loading", false)
        } catch (error) {
            // this.dispatch('user/logout')
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async logout({ commit }) {
        let data = {}
        if (sessionStorage.getItem("getOneUser")) {
            data = JSON.parse(
                encryptor.decrypt(sessionStorage.getItem("getOneUser"))
            )
        }
        const json = {
            userId:
                data && data.data && data.data.userData
                    ? data.data.userData.userId
                    : ""
        }
        service.apiCallWithHeader2("/v2/logout", json)
        commit("set", null)
        const accessToken = this.$auth.getToken("customLocal")
        const abc = localStorage.getItem("accessTokenNewWallet")
        let jsonToken
        if (abc) {
            jsonToken = JSON.parse(abc)
        }
        localStorage.clear()
        sessionStorage.clear()
        commit("set", null)
        try {
            this.$auth.$storage.removeUniversal("timezone")
            this.$auth.$storage.removeUniversal("userConfig")
            this.$auth.reset()
            await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: process.env.tripleWallet
                    ? "tripleWallet/logout"
                    : "member/logout",
                data: process.env.tripleWallet
                    ? {
                          accessToken:
                              jsonToken && jsonToken.accessToken
                                  ? jsonToken.accessToken
                                  : "",
                          accessToken_bonus:
                              jsonToken && jsonToken.accessToken_bonus
                                  ? jsonToken.accessToken_bonus
                                  : "",
                          accessToken_sp:
                              jsonToken && jsonToken.accessToken_sp
                                  ? jsonToken.accessToken_sp
                                  : ""
                      }
                    : { accessToken }
                // headers: {
                //     tokenRequired: true,
                //     "x-key-id": "Bearer " + accessToken
                // }
            })
            window.location.reload()
        } catch (error) {
            commit("set_error", error)
        }
    },
    async getWhatAppDepositSetting({ commit, state }) {
        try {
            if (
                localStorage.getItem("tripleWalletType") === "SportBonus" &&
                this.state.user.configurationData &&
                this.state.user.configurationData._id &&
                !this.state.user.configurationData.isSportBonusWalletOn
            ) {
                console.log("gyab", this.state.user.configurationData)
                localStorage.removeItem("tripleWalletType")
            }
            if (
                localStorage.getItem("tripleWalletType") === "Bonus" &&
                this.state.user.configurationData &&
                this.state.user.configurationData._id &&
                !this.state.user.configurationData.isBonusWalletOn
            ) {
                console.log("gyab1", this.state.user.configurationData)
                localStorage.removeItem("tripleWalletType")
            }

            if (
                sessionStorage.getItem("walletConfig") &&
                sessionStorage.getItem("walletConfig_timezone")
            ) {
                const aa = sessionStorage.getItem("walletConfig_timezone")
                const now = new Date().getTime()
                const distance = now - aa
                const seconds = Math.floor(distance / 1000)
                const minutes = Math.floor(seconds / 60)
                if (minutes >= 10) {
                    sessionStorage.removeItem("walletConfig")
                    sessionStorage.removeItem("walletConfig_timezone")
                }
            }
            if (sessionStorage.getItem("walletConfig")) {
                commit(
                    "set_configData",
                    JSON.parse(sessionStorage.getItem("walletConfig"))
                )
            } else if (localStorage && localStorage.getItem("accessToken")) {
                const res = await service.apiCallWithHeader(
                    "/v2/getWhatsAppDepositConfigUi"
                )
                if (res && res.status === 200 && res.data && res.data.data) {
                    sessionStorage.setItem(
                        "walletConfig",
                        JSON.stringify(res.data.data)
                    )
                    sessionStorage.setItem(
                        "walletConfig_timezone",
                        new Date().getTime()
                    )
                    commit("set_configData", res.data.data)
                    if (!res.data.data.isWhatsAppDeposit) {
                        res.data.data.isWhatsAppDeposit = false
                    }
                } else {
                    commit("set_configData", null)
                }
            }
        } catch (err) {
            commit("set_configData", null)
        }
    },
    async getconfigs({ commit, state }) {
        try {
            // This will stored data for 10mins.
            if (
                sessionStorage.getItem("whatsapp_data_timezone") &&
                sessionStorage.getItem("whatsapp_data")
            ) {
                const aa = sessionStorage.getItem("whatsapp_data_timezone")
                const now = new Date().getTime()
                const distance = now - aa
                const seconds = Math.floor(distance / 1000)
                const minutes = Math.floor(seconds / 60)
                if (minutes >= 10) {
                    sessionStorage.removeItem("whatsapp_data")
                    sessionStorage.removeItem("whatsapp_data_timezone")
                }
            }
            const path = ["/wallet", "/wallet/deposit", "/wallet/withdrawal"]
            if (process.client) {
                if (
                    sessionStorage.getItem("whatsapp_data") &&
                    !path.includes(window.location.pathname)
                ) {
                    const data = await JSON.parse(
                        encryptor.decrypt(
                            sessionStorage.getItem("whatsapp_data")
                        )
                    )
                    commit("set_whatsapp_data", data)
                    return
                }
            }

            const res = await service.apiCallWithUserManagementWithoutData(
                "/v2/getConfigsForUi"
            )
            if (
                res &&
                res.status === 200 &&
                res.data &&
                res.data.data &&
                res.data.data.number
            ) {
                commit("set_whatsapp_data", res.data.data)
                sessionStorage.setItem(
                    "whatsapp_data",
                    encryptor.encrypt(JSON.stringify(res.data.data))
                )
                sessionStorage.setItem(
                    "whatsapp_data_timezone",
                    new Date().getTime()
                )
            } else {
                commit("set_whatsapp_data", null)
            }
        } catch (err) {
            commit("set_whatsapp_data", null)
        }
    },
    async getFantasyConfigs({ commit }) {
        try {
            const res = await servicefb.apiCallWithAccessTokenAndWithOutData(
                "/v2/getFantasyOnOffForUi"
            )
            // res.data.data.isFantasyOnOff = false
            if (res && res.status === 200 && res.data && res.data.data) {
                if (!res.data.data.isFantasyOnOff) {
                    res.data.data.isFantasyOnOff = false
                }
                commit("set_fantasy_ON_Off", res.data.data)
                // commit('set_whatsapp_link', res.data.data)
            } else {
                // return null
                commit("set_fantasy_ON_Off", null)
            }
        } catch (err) {
            commit("set_fantasy_ON_Off", null)
        }
    },
    async getNotfiConfigs({ commit }) {
        try {
            const res = await service.apiCallWithOutData(
                "/MarqueeNotification/getMarqueeNotifications"
            )
            if (
                res &&
                res.status === 200 &&
                res.data &&
                res.data[0] &&
                res.data[0].marqueeText
            ) {
                commit("set_notificationData", res.data[0].marqueeText)
            } else {
                commit("set_notificationData", "")
            }
        } catch (err) {
            commit("set_notificationData", "")
        }
    },
    async checkLogin({ commit }) {
        const _accessToken = this.$auth.getToken("customLocal")
        try {
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "Member/userLoginCheck",
                data: { _accessToken }
            })
            if (response && response.data && response.data.data) {
                commit("set_user_verify_data", response.data)
            }
            // console.log(response)
        } catch (err) {
            console.log(err)
        }
    },
    async getDeploymentVersion({ commit }) {
        const level = "Frontend"
        const _accessToken = this.$auth.getToken("customLocal")
        try {
            const res = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "Configurations/getAppVersionForCacheCheck",
                data: { level, _accessToken }
            })
            if (
                res &&
                res.data.value &&
                res.data &&
                res.data.data &&
                res.data.data.appVersion
            ) {
                return res.data.data.appVersion
            } else if (res && res.data && res.data.error) {
                this.$store.dispatch("user/logout")
            } else {
                return "9999.9999.9999.9999"
            }
        } catch (err) {
            commit("set_error", err)
            if (
                err &&
                typeof err.toString() === "string" &&
                err.toString().toLowerCase() === "error: network error"
            ) {
                console.log(
                    "In your Region Our Service Not Allowed",
                    err.toString()
                )
            }
            this.$auth.reset()
            localStorage.clear()
            return "9999.9999.9999.9999"
        }
    },
    async getSecretKey({ commit }, data) {
        try {
            const res = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "member/getSecreteKey",
                data: {
                    name: data.siteName,
                    userName: data.userName,
                    _accessToken: data.accessToken
                }
            })
            if (res && res.data && res.data.data && res.data.value) {
                return res
            } else if (res && res.data && res.data.error) {
                console.log("err", res.data.error)
            }
        } catch (err) {
            commit("set_error", err)
        }
    },
    async verifySecretKey({ commit }, data) {
        try {
            const res = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "member/verifySecreteKey",
                data: {
                    _accessToken: data.accessToken,
                    token: data.token
                }
            })
            if (res) {
                return res
            } else if (res && res.data && res.data.error) {
                console.log("err", res.data.error)
            }
        } catch (err) {
            commit("set_error", err)
        }
    },
    async change2fa({ commit }, data) {
        try {
            const res = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "member/change2faAuthFlag",
                data: {
                    _accessToken: data.accessToken,
                    username: data.userName,
                    is2faEnabled: data.is2faEnabled
                }
            })
            if (res) {
                return res
            } else if (res && res.data && res.data.error) {
                console.log("err", res.data.error)
            }
        } catch (err) {
            commit("set_error", err)
        }
    },
    async getDgconfigs({ commit }) {
        try {
            commit("set_loading", true)
            const res = await service.apiCallWithUserManagementWithoutData(
                "/v2/getDgConfigsForUi"
            )
            if (
                res &&
                res.status === 200 &&
                res.data &&
                res.data.data &&
                res.data.data.dgNumber
            ) {
                commit("set_dg_whatsapp_data", res.data.data)
            } else {
                commit("set_dg_whatsapp_data", null)
            }
            commit("set_loading", false)
        } catch (err) {
            commit("set_dg_whatsapp_data", null)
            commit("set_loading", false)
        }
    },
    addToDownload() {
        try {
            service.apiCallWithHeader("/v2/countOfDownloads", {})
        } catch (err) {
            return err
        }
    },
    async checkAffiliateProcess({ commit }, data) {
        if (process.client) {
            if (localStorage && !localStorage.getItem("accessToken")) {
                if (data && data.query && data.query.btag) {
                    localStorage.setItem("signup_affilates", data.query.btag)
                }
                if (
                    data &&
                    data.query &&
                    data.query.btag &&
                    constant.affiliatesCodeArray &&
                    constant.affiliatesCodeArray.length > 0 &&
                    !constant.affiliatesCodeArray.includes(data.query.btag)
                ) {
                    await localStorage.setItem(
                        "user_affilates",
                        data.query.btag
                    )
                    await localStorage.setItem(
                        "user_affiliates_timezone",
                        new Date().getTime()
                    )
                } else if (
                    localStorage.getItem("user_affiliates_timezone") &&
                    localStorage.getItem("user_affilates")
                ) {
                    const aa = localStorage.getItem("user_affiliates_timezone")
                    const now = new Date().getTime()
                    const distance = now - aa
                    const seconds = Math.floor(distance / 1000)
                    const minutes = Math.floor(seconds / 60)
                    const hour = Math.floor(minutes / 60)
                    if (hour >= 8) {
                        localStorage.removeItem("user_affilates")
                        localStorage.removeItem("signup_affilates")
                    }
                }
            }
        }
    },
    async setAffiliateUser({ commit }, data) {
        try {
            await service.apiCallWithHeader2("/v2/updateBtag", {
                btag: data
            })
        } catch (error) {}
    }
}
