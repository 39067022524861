import Vue from "vue"
import isString from "lodash/isString"
import isNaN from "lodash/isNaN"
Vue.mixin({
    methods: {
        getLogo(theme) {
            let logo = process.env.THEME_LOGO
            if (theme && !theme.isDark) {
                logo = process.env.THEME_LOGO_LIGHT
            } else {
                logo = process.env.THEME_LOGO
            }
            try {
                return require(`@/assets/logos/${logo}`)
            } catch (error) {
                return ""
            }
        },
        roundToLetter: (num) => {
            if (!!num && !isNaN(num)) {
                // if (num >= 1000000000) {
                //   return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
                // } else if (num >= 10000000) {
                //   return (num / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr'
                // } else if (num >= 100000) {
                //   return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L'
                // } else if (num >= 1000) {
                //   return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
                // }
                return num.toLocaleString("en-IN", { maximumFractionDigits: 0 })
            } else {
                return num
            }
        },
        getMyRate() {
            let myRate
            if (
                this.$auth.loggedIn &&
                this.$auth.user.currencyType === "POINT@1"
            ) {
                myRate = "1"
            } else {
                myRate = this.$auth.user.rate.find(
                    (rate) => rate.game.name === "SportsBook"
                ).memberRatePercent
            }
            return myRate
        },
        countDecimals(value = 1) {
            if (value % 1 !== 0) return value.toString().split(".")[1].length
            return 0
        },
        incOrDec: (value, event) => {
            let output = 0
            if (isString(value)) {
                value = parseFloat(value)
            }
            if (isNaN(value) || value < 1.01) {
                value = 1.01
            }

            if (event === "up" && value < 1000) {
                if (value >= 1.01 && value < 2) {
                    output = 0.01
                } else if (value >= 2 && value < 3) {
                    output = 0.02
                } else if (value >= 3 && value < 4) {
                    output = 0.05
                } else if (value >= 4 && value < 6) {
                    output = 0.1
                } else if (value >= 6 && value < 10) {
                    output = 0.2
                } else if (value >= 10 && value < 20) {
                    output = 0.5
                } else if (value >= 20 && value < 30) {
                    output = 1
                } else if (value >= 30 && value < 50) {
                    output = 2
                } else if (value >= 50 && value < 100) {
                    output = 5
                } else if (value >= 100 && value < 1000) {
                    output = 10
                }

                value = parseFloat(value) + parseFloat(output)

                value = +(Math.round(value + "e+2") + "e-2")
            } else if (event === "down" && value > 1.01) {
                if (value > 1.01 && value <= 2) {
                    output = 0.01
                } else if (value >= 2 && value < 3) {
                    output = 0.02
                } else if (value >= 3 && value < 4) {
                    output = 0.05
                } else if (value >= 4 && value < 6) {
                    output = 0.1
                } else if (value >= 6 && value < 10) {
                    output = 0.2
                } else if (value >= 10 && value < 20) {
                    output = 0.5
                } else if (value >= 20 && value < 30) {
                    output = 1
                } else if (value >= 30 && value < 50) {
                    output = 2
                } else if (value >= 50 && value < 100) {
                    output = 5
                } else if (value >= 100 && value < 1000) {
                    output = 10
                }
                value = parseFloat(value) - parseFloat(output)
                value = +(Math.round(value + "e+2") + "e-2")
            }

            return value
        },

        incOrDecValidation: (value, incremental) => {
            const countDecimals = (value) => {
                if (value % 1 !== 0)
                    return value.toString().split(".")[1].length
                return 0
            }
            const decimalCount = countDecimals(incremental)
            const rounder = Math.pow(10, decimalCount)
            const rounded = Math.round(value * rounder)
            const roundedInc = incremental * rounder
            if (rounded % roundedInc !== 0) {
                for (let i = 1; i <= roundedInc; i++) {
                    const up = rounded + i
                    const down = rounded - i
                    if (up % roundedInc === 0) {
                        return up / rounder
                    } else if (down % roundedInc === 0) {
                        return down / rounder
                    }
                }
            }
            if (decimalCount === 0) {
                return rounded
            }
            return value
        },
        betfairOddsCheck(market) {
            if (market && market.betfairOdds === "true") {
                return true
            } else if (market && market.betfairOdds === "false") {
                return false
            } else {
                return true
            }
        },
        checkIfSuspended: (market, runner, socketData) => {
            if (runner === "fancy") {
                if (market.isMarketSuspended) {
                    return "Market Suspended"
                } else if (market.ballChalu) {
                    return "Ball Running"
                } else if (market.isSuspended) {
                    return "Suspended"
                }
            }

            if (socketData && market.betfairId === socketData.marketId) {
                market = { ...market, ...socketData }
            }

            if (
                market &&
                (market.allZero ||
                    market.betfairStatus === "SUSPENDED" ||
                    market.isSuspended === "Yes" ||
                    market.status === "Disable")
            ) {
                return "Suspended"
            } else if (
                runner &&
                (runner.isSuspended === "Disable" ||
                    runner.status === "Disable")
            ) {
                return "Suspended"
            } else if (market && market.betfairStatus === "CLOSED") {
                return "Closed"
            } else if (
                market &&
                (market.inPlayStatus === "Close" ||
                    market.inPlayStatus === "Closed")
            ) {
                return "No Text"
            } else if (market && market.marketStatus === "Disable") {
                return "Ball Running"
            } else if (runner && runner.isMarketSuspended === "Disable") {
                return "Ball Running"
            } else if (
                runner &&
                (runner.isSuspended || runner.isMarketSuspended)
            ) {
                return "Suspended"
            } else if (
                market &&
                (market.marketStatus === "Deactivated" ||
                    market.marketStatus === "Suspended" ||
                    market.marketStatus === "Cancelled" ||
                    market.marketStatus === "Settled" ||
                    market.marketStatus === "Handed_Over" ||
                    market.marketStatus === "Other")
            ) {
                return "Suspended"
            } else if (market && market.status === "Closed") {
                return "No Text"
            }
            // else if (market && market.betfairOdds) {
            //   return 'No Text'
            // }
            return false
        }
    }
})
