/*eslint-disable*/
import axios from "axios"
import hostName from "../plugins/hostname"

// var hostname = process.env.dynamicDomain
//     ? "localhost"
//     : window.location.hostname
// var mainDomainLink =
//     hostname && hostname === "localhost"
//         ? process.env.basicDomain
//         : hostname &&
//           (hostname.includes("m.") ||
//               hostname.includes("bd.") ||
//               hostname.includes("www.") ||
//               hostname.includes("fantasy."))
//         ? hostname.replace(/m.|www.|bd.|fantasy./gi, "")
//         : hostname
if (process.client) {
    var mainDomainLink = hostName.DomainCheck(window.location.hostname)
}

export default {
    async apiCallWithOutData(url) {
        // if (process.server) {
        //   return
        // }
        return await axios
            .post(process.env.userAdminUrl + mainDomainLink + url)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },
    async apiCallWithData(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.userAdminUrl + mainDomainLink + url, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },

    async apiCallWithHeader2(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.userAdminUrl + mainDomainLink + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async getStoreEnabled(url) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.STORE_API + mainDomainLink + url)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async withdrawCallWithHeader(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.withdrawalUrl + mainDomainLink + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },

    async apiCallWithHeader(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.userAdminUrl + mainDomainLink + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                if (
                    res &&
                    res.status === 200 &&
                    res.data &&
                    res.data.userData &&
                    res.data.userData.referalLink &&
                    res.data.userData.referalCode &&
                    res.data.userData.name
                ) {
                    res.data.userData.referalLink = `${window.location.origin}/referalLink/${res.data.userData.referalCode}/${res.data.userData.name}`
                }
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithAgent(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.fairplayBankAgent + mainDomainLink + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },

    async apiCallWithHeaderUsingGet(url) {
        return axios
            .get(process.env.userAdminUrl + mainDomainLink + url, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },

    async updateApiCallWithHeader(url) {
        if (process.server) {
            return
        }
        return await axios
            .put(
                process.env.userAdminUrl + mainDomainLink + url,
                {},
                {
                    headers: {
                        accessToken: localStorage.getItem("accessToken")
                    }
                }
            )
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },
    async apiCallWithUserManagementWithHeader(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(
                process.env.fairplayManagementUrl + mainDomainLink + url,
                data,
                {
                    headers: {
                        accessToken: localStorage.getItem("accessToken")
                    }
                }
            )
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithGetUserManagementWithHeader(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .get(process.env.fairplayManagementUrl + mainDomainLink + url, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithUserManagement(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(
                process.env.fairplayManagementUrl + mainDomainLink + url,
                data
            )
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithUserManagementForAnotherDomain(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(
                process.env.fairplayManagementDomainUrl + mainDomainLink + url,
                data
            )
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithGetUserManagement(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .get(process.env.fairplayManagementUrl + mainDomainLink + url)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithFairplaySockets(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .get(process.env.fairplaySocketUrl + mainDomainLink + url, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },
    async apiCallWithUserManagementWithoutData(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(
                process.env.fairplayManagementUrl + mainDomainLink + url,
                data
            )
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithHeader2UsingGet(url) {
        return axios
            .get(process.env.fairplayManagementUrl + mainDomainLink + url, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallForGlobalLock(url, data) {
        return await axios
            .post(process.env.GLOBAL_LOCK + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithPaymentGateway(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.paymentGatewayUrl + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    }
}
