import LocalScheme from "@nuxtjs/auth/lib/schemes/local"
export default class CustomScheme extends LocalScheme {
    async login(endpoint) {
        this.$auth.ipqualityStatus = true
        if (!this.options.endpoints.login) {
            return
        }

        // Ditch any leftover local tokens before attempting to log in
        await this.$auth.reset()

        // const { response, result } = await this.$auth.request(
        //   endpoint,
        //   this.options.endpoints.login,
        //   true
        // )

        // if (!result) {
        //   const error = new Error('Invalid credentials')
        //   return error
        // }

        if (this.options.tokenRequired) {
            const token = endpoint.data.accessToken

            this.$auth.setToken(this.name, token)
            this._setToken("Bearer " + token)
        }

        if (this.options.autoFetchUser) {
            await this.fetchUser()
        }

        return endpoint.data.accessToken
    }

    async fetchUser(endpoint) {
        // Token is required but not available
        if (this.options.tokenRequired && !this.$auth.getToken(this.name)) {
            return
        }

        // User endpoint is disabled.
        if (!this.options.endpoints.user) {
            this.$auth.setUser({})
            return
        }

        // Try to fetch user and then set
        const _accessToken = this.$auth.getToken("customLocal")
        try {
            this.$auth.setToken(this.name, _accessToken)
            this._setToken("Bearer " + _accessToken)

            const { response } = await this.$auth.request(
                {
                    baseURL: process.env.USER_V2_API_URL,
                    data: { _accessToken }
                },
                this.options.endpoints.user,
                true
            )
            if (response.data.value) {
                this.$auth.setUser(response.data.data)
            } else {
                return this.$auth.reset()
            }
        } catch (error) {
            return this.$auth.reset()
        }
        try {
            // jwt time expiration api
            const { response } = await this.$auth.request(
                {
                    baseURL: process.env.USER_V2_API_URL,
                    data: { token: _accessToken }
                },
                this.options.endpoints.verifyjwt,
                true
            )
            if (response.data.value) {
                const todayDate = new Date()
                const expDate = new Date(response.data.data.expDate)
                // jwt expire time calculations
                const todayTime = expDate - todayDate

                // logout code after jwt expirations
                if (todayTime > 0) {
                    setTimeout(() => {
                        this.logout()
                    }, todayTime)
                } else {
                    setTimeout(() => {
                        this.logout()
                    }, 100)
                }
            } else {
                setTimeout(() => {
                    this.logout()
                }, 100)
            }
        } catch (error) {
            setTimeout(() => {
                this.logout()
            }, 100)
        }
    }

    async logout(endpoint) {
        // Only connect to logout endpoint if it's configured
        const accessToken = this.$auth.getToken("customLocal")
        const abc = localStorage.getItem("accessTokenNewWallet")
        let jsonToken
        if (abc) {
            jsonToken = JSON.parse(abc)
        }
        localStorage.clear()
        sessionStorage.clear()
        if (this.options.endpoints.logout) {
            await this.$auth
                .requestWith(this.name, endpoint, {
                    baseURL: process.env.USER_V2_API_URL,
                    ...this.options.endpoints.logout,
                    data: process.env.tripleWallet
                        ? {
                              accessToken:
                                  jsonToken && jsonToken.accessToken
                                      ? jsonToken.accessToken
                                      : "",
                              accessToken_bonus:
                                  jsonToken && jsonToken.accessToken_bonus
                                      ? jsonToken.accessToken_bonus
                                      : "",
                              accessToken_sp:
                                  jsonToken && jsonToken.accessToken_sp
                                      ? jsonToken.accessToken_sp
                                      : ""
                          }
                        : { accessToken }
                })
                .catch(() => {})
        }
        this.removeLocalStorage()
        // But reset regardless
        return this.$auth.reset()
    }

    removeLocalStorage() {
        localStorage.removeItem("wacCasinoIds")
    }
}
