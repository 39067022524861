export const state = () => ({
    adminARUrl: undefined,
    loading: false,
    liveCasinoSlotGameData: [],
    error: {},
    casinoGames: [],
    casinoIframeURL: ""
})

export const mutations = {
    // casino sub-games api
    set_game(state, data) {
        state.casinoGames = data
    },

    // casino sub-games iframe Url api
    set_game_url(state, data) {
        state.casinoIframeURL = data.launch_request_url
    },

    // live casino iframe apis
    set_data(state, data) {
        if (data && data.entry) {
            state.adminARUrl = process.env.AR1_URL + data.entry
        }
    },
    // live casino slot games apis
    set_data_slot_game(state, data) {
        state.liveCasinoSlotGameData = data
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}

export const actions = {
    // don't remove its ESof Prologic
    // casino sub-games api
    async getGames({ commit }, payload) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.PROLOGIC_URL,
                url: "getGames",
                data: {
                    payload,
                    _accessToken: this.$auth.getToken("customLocal")
                }
            })

            commit("set_game", response.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },

    // live casino iframe api
    async getLiveCasinoUrl({ commit }, dataTable) {
        commit("set_data", "")
        let accessToken, userId, username
        if (this.$auth.loggedIn) {
            accessToken = this.$auth.getToken("customLocal")
            userId = this.$auth.user._id
            username = this.$auth.user.username
        }

        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.AR_API_URL,
                url: "Sessions/createLoginSidNew",
                data: {
                    nickname: username + "_" + userId,
                    game: "slots",
                    userId,
                    accessToken,
                    mobile: false,
                    currencyType: "INR",
                    table: dataTable
                }
            })
            commit("set_data", response.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },

    // Live Casino Slot Game api
    async getLiveCasinoSlotGameUrl({ commit }, dataTable) {
        commit("set_data_slot_game", "")
        let accessToken
        if (this.$auth.loggedIn) {
            accessToken = this.$auth.getToken("customLocal")
        }

        try {
            commit("set_loading", true)
            // baseURL: process.env.CONFIG_API_URL,
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.CONFIG_V2_API_URL,
                url: "/game/getTableByGame",
                data: {
                    gameName: "Live Casino",
                    _accessToken: accessToken
                }
            })
            commit("set_data_slot_game", response.data.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
